import React from 'react'
import { PageProps, Link } from 'gatsby'
import Layout from '../../components/Layout'
import { Paragraph, Title } from '../../components/Typography'
import { Link as MuiLink } from '@material-ui/core'

const Success: React.FC<PageProps> = () => (
  <Layout path="Contact" meta={[{ name: 'robots', content: 'noindex' }]}>
    <Title>送信完了</Title>
    <Paragraph>メッセージが送信されました。ありがとうございました。</Paragraph>
    <Paragraph>
      <MuiLink component={Link} to="/contact/">
        フォームへ戻る
      </MuiLink>
    </Paragraph>
  </Layout>
)

export default Success
